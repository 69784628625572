import React from 'react';
import PostBlock from '../components/PostBlock';
import './RelatedPostsBlock.scss';

export const RelatedPostsBlock = (props) => {
  const {
    id,
    theme,
    title,
    posts,
    pathPrefix = 'blog'
  } = props;
  if (!posts) return null;
  return (
    <section className="related-posts-block">
      <PostBlock
        posts={posts ? posts.filter(post => post.slug !== 'dummy-post') : []}
        title={title}
        theme={theme || 'off-white-lighter'}
        isSlider
        pathPrefix={pathPrefix}
        sliderContainer={id ? id.split('').reverse().join('') : null}
      />
    </section>
  );
}
