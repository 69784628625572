import React from 'react';
import { Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './CallToActionGroup.scss';

export const CallToActionGroup = (props) => {
  const {
    background,
    callToActions,
    wordpressUrl
  } = props;
  return (
    <section className={`call-to-action-group ${background}`}>
      <div className="wrap wide">
        {callToActions && (
          <div className="call-to-actions">
            {callToActions.map(callToAction => {
              const {
                theme,
                title,
                headline,
                bodyText,
                hasButton,
                buttonLink,
                buttonLabel,
              } = callToAction;
              return (
                <div className={`call-to-action ${theme}`}>
                  {title && <h3 className="title">{decodeEntities(title)}</h3>}
                  <div className="inner-container">
                    {headline && <span className="headline">{decodeEntities(headline)}</span>}
                    {wordpressUrl && <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />}
                    {hasButton && (
                      <Link className={`styled-button ${theme === 'primary' ? 'light' : ''}`} aria-label={buttonLabel} to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>
                        <span>{decodeEntities(buttonLabel)}</span>
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}
