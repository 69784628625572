import React from 'react';
import { Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './ButtonGroup.scss';

export const ButtonGroup = (props) => {
  const { buttons, theme, wordpressUrl } = props;
  if (!buttons) return null;
  return (
    <div className="button-group">
      {buttons.map(button => (
        <Link className={`button styled-button ${theme}`} to={getPageSlugFromWpLink(button.buttonLink, wordpressUrl)} aria-label={button.buttonLabel} key={button.buttonLink}>
          <span>{decodeEntities(button.buttonLabel)}</span>
        </Link>
      ))}
    </div>
  )
}
