import React from 'react';
import './ProgressBar.scss';

export const ProgressBar = props => {
  const {
    numStages,
    activeStage
  } = props;
  const stages = Array.from(Array(numStages).keys());
  return (
    <div className="progress-bar">
      {stages.map((stage, index) => (
        <div key={index} className={`stage ${activeStage === index + 1 ? 'active' : ''}`} />
      ))}
    </div>
  );
}
