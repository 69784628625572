import React from 'react';
import { decodeEntities } from '../utils/htmlParse';
import './TeamMemberAchievements.scss';

export const TeamMemberAchievements = (props) => {
  const {
    title,
    Achievements: achievements
  } = props;
  return (
    <section className="team-member-achievements">
      <div className="wrap">
        <h2 className="title">{decodeEntities(title)}</h2>
        {achievements && (
          <div className="achievements">
            {achievements.map((achievement, index) => (
              <div className="achievement" key={index}>
                <span className="title">{decodeEntities(achievement.title)}</span>
                <p className="description">{decodeEntities(achievement.description)}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
