import React from 'react';
import { Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './PageHeaderShort.scss';

export const PageHeaderShort = (props) => {
  const {
    parent,
    title,
    subtitle,
    increasedPadding,
  } = props;
  return (
    <section className="page-header-short">
      <div className="top">
        <div className="breadcrumbs">
          <div className="wrap">
            <Link className="crumb" to="/" aria-label="Home">Home</Link> /
            {parent && (
              <>
                <Link className="crumb" to={`/${parent.slug}/`} aria-label={parent.title}> {decodeEntities(parent.title)}</Link> /
              </>
            )}
            <span className="crumb"> {decodeEntities(title)}</span>
          </div>
        </div>
        <div className="title">
          <div className="wrap"><h1>{decodeEntities(title)}</h1></div>
        </div>
      </div>
      <div className="wrap">
        {subtitle && <div className={`subtitle ${increasedPadding ? 'extra-padding' : ''}`} dangerouslySetInnerHTML={{__html: subtitle}} />}
      </div>
    </section>
  );
}
