import React from 'react';
import './ContactForm.scss';

export const ContactForm = (props) => {
  const { siteUrl } = props;
  return (
    <form
      className="contact-form"
      method="post"
      action="https://api.formbucket.com/f/buk_D0sEGoARcXnwOisKZwDRB08Z"
      onSubmit="return validateForm()"
    >
      <input name="thank_you_url" value={`${siteUrl}/thank-you`} type="hidden" />
      {/* eslint-disable-next-line */}
      <label htmlFor="name">Name</label>
      <input type="text" name="name" placeholder="Name" className="name dark" required />
      {/* eslint-disable-next-line */}
      <label>Email</label>
      <input type="email" name="email" placeholder="Email" className="email dark" required />
      {/* eslint-disable-next-line */}
      <label>Phone</label>
      <input type="phone" name="phone" placeholder="Phone" className="phone dark" required />
      {/* eslint-disable-next-line */}
      <label>Select your preferred method of contact</label>
      <select className="preference dark" name="contact-preference" required>
        <option selected disabled value="">Preferred method of contact</option>
        <option value="email">Email</option>
        <option value="phone">Phone</option>
      </select>
      {/* eslint-disable-next-line */}
      <label>I am enquiring about</label>
      <select className="enquiry-type dark" name="enquiry-type" required>
        <option selected disabled value="">I am enquiring about</option>
        <option value="Wealth Planning">Wealth Planning</option>
        <option value="Investment Management">Investment Management</option>
        <option value="Legacy Planning">Legacy Planning</option>
        <option value="Risk Insurance">Risk Insurance</option>
        <option value="Retirement Planning">Retirement Planning</option>
        <option value="Separation or Divorce">Separation or Divorce</option>
      </select>
      <textarea className="message dark" rows="5" name="message" placeholder="Tell us a bit about yourself and how we can help" />
      <input type="text" name="formbucket" placeholder="Form Bucket" className="formbucket-field" />
      <button className="styled-button" type="submit" aria-label="Contact us"><span>Contact us</span></button>
    </form>
  );
}
