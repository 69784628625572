import React from 'react';
import OnVisible from 'react-on-visible';
import Image from '../components/Image';
import { ButtonGroup } from '../components/ButtonGroup';
import { HTMLContent } from '../components/Content';
import { decodeEntities } from '../utils/htmlParse';
import './GridBlock.scss'

export const GridBlock = (props) => {
  const {
    wordpressUrl,
    photo,
    title,
    headline,
    bodyText,
    orientation = "photo-left",
    theme = "white",
    hasButtons,
    buttons,
  } = props;
  const getButtonTheme = (gridTheme) => {
    return gridTheme === 'primary' ? 'light' : '';
  };
  return (
    <section className={`grid-block ${orientation} ${theme}`}>
      <OnVisible className="container">
        <div className="image-col">
          <Image image={photo} />
        </div>
        <div className="text-col">
          <div className="outer-container">
            <span className="title">{decodeEntities(title)}</span>
            <div className="inner-container">
              <h2 className="headline">{decodeEntities(headline)}</h2>
              <HTMLContent className="body-text" content={bodyText} />
              { hasButtons && (
                <ButtonGroup wordpressUrl={wordpressUrl} buttons={buttons} theme={getButtonTheme(theme)} />
              )}
            </div>
          </div>
        </div>
      </OnVisible>
    </section>
  )
}
