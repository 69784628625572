import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Image';
import { decodeEntities } from '../utils/htmlParse';
import { SocialLink } from '../components/SocialLink';
import './TeamMemberIntro.scss';

const TEAM_MEMBERS_SLUG = 'about/our-team';

export const TeamMemberIntro = (props) => {
  const {
    parent,
    teamMemberName,
    role,
    introText,
    image,
    homeNumber,
    mobileNumber,
    email,
    socialLinks,
    bodyText,
    location
  } = props;
  return (
    <section className="team-member-intro">
      <section className="page-header-short">
        <div className="top">
          <div className="breadcrumbs">
            <div className="wrap">
              <Link className="crumb" to="/" aria-label="Home">Home</Link> /
              <Link className="crumb" to={`/${TEAM_MEMBERS_SLUG}/`} aria-label="Meet Our Team"> Meet Our Team</Link> /
              <span className="crumb"> {decodeEntities(teamMemberName)}</span>
            </div>
          </div>
          <div className="title">
            <div className="wrap">
              <h1>{decodeEntities(teamMemberName)}</h1>
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="role" dangerouslySetInnerHTML={{__html: role}} />
          <div className="subtitle" dangerouslySetInnerHTML={{__html: introText}} />
        </div>
      </section>
      <div className={`intro ${socialLinks ? 'has-socials' : ''}`}>
        <div className="social-links">
          {socialLinks && socialLinks.map(socialLink => (
            <div className="link" key={socialLink.url}>
              <SocialLink socialLink={socialLink} theme="dark" />
            </div>
          )) }
        </div>
        <div className="wrap">
          <div className="left">
            {(homeNumber || mobileNumber || email) && (
              <div className="contact-info">
                {/* eslint-disable-next-line */}
                {homeNumber && <span>D <a href={`tel:${homeNumber}`}>{decodeEntities(homeNumber)}</a></span>}
                {/* eslint-disable-next-line */}
                {mobileNumber && <span>M <a href={`tel:${mobileNumber}`}>{decodeEntities(mobileNumber)}</a></span>}
                {/* eslint-disable-next-line */}
                {email && <span><a href={`mailto:${email}`}>{decodeEntities(email)}</a></span>}
              </div>
            )}
            <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />
          </div>
          <div className="right">
            <div className="image">
              <Image image={image} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
