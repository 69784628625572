import React from 'react';

export const PdfIcon = (props) => {
  const {
    color = '#817B71',
  } = props;
  return (
    <svg viewBox="0 0 21 27" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill={color} fillRule="nonzero" d="M0 0h14.948L21 6.097V27H0z" />
        <path fill="#FFF" fillRule="nonzero" d="M14 6h7v1h-7z" />
        <path fill="#FFF" fillRule="nonzero" d="M15 0v7h-1V0zM3 13h15v1H3zM3 10h15v1H3zM3 7h9v1H3zM3 4h9v1H3zM3 16h15v1H3z" />
        <path d="M6.618 22.234v1.32H6V20h1.36c.397 0 .712.103.946.31.233.207.35.48.35.82 0 .349-.114.62-.343.813-.228.194-.549.29-.96.29h-.735zm0-.496h.742c.22 0 .387-.051.503-.155.115-.103.173-.252.173-.448a.615.615 0 0 0-.176-.46c-.117-.115-.278-.174-.483-.177h-.76v1.24zm2.734 1.817V20h1.05c.314 0 .593.07.836.21.243.14.432.339.565.596.134.257.2.551.2.883v.179c0 .337-.067.633-.201.888a1.414 1.414 0 0 1-.574.591 1.723 1.723 0 0 1-.853.208H9.352zm.618-3.057v2.564h.403c.323 0 .572-.102.745-.304.174-.203.262-.494.265-.873v-.198c0-.386-.084-.68-.251-.884-.168-.203-.411-.305-.73-.305H9.97zm4.853 1.55h-1.43v1.507h-.618V20h2.258v.498h-1.64v1.057h1.43v.493z" fill="#FFF" />
      </g>
    </svg>
  );
}
