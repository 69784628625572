import React from 'react';

export const InvestmentPlanContactForm = (props) => {
  const { siteUrl } = props;
  return (
    <form
      className="investment-plan-contact-form"
      method="post"
      action="https://api.formbucket.com/f/buk_nbRbYtYabKmNdIO1JyZ0sH3W"
      onSubmit="return validateForm()"
    >
      <input name="thank_you_url" value={`${siteUrl}/thank-you`} type="hidden" />
      {/* eslint-disable-next-line */}
      <label htmlFor="name">Name</label>
      <input type="text" name="name" placeholder="Name" className="name dark" required />
      {/* eslint-disable-next-line */}
      <label>Email</label>
      <input type="email" name="email" placeholder="Email" className="email dark" required />
      {/* eslint-disable-next-line */}
      <label>Phone</label>
      <input type="phone" name="phone" placeholder="Phone" className="phone dark" required />
      <input type="text" name="formbucket" placeholder="Form Bucket" className="formbucket-field" />
      <button className="styled-button" type="submit" aria-label="Let's Talk"><span>Let's talk</span></button>
    </form>
  );
}
