import React from 'react';
import OnVisible from 'react-on-visible';
import { Link } from 'gatsby';
import Image from '../components/Image';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './PageHeader.scss'

const getAccentPiece = (type, graphic, quote, videoUrl) => {
  switch(type) {
    case "plain":
      return null;
    case "quote":
      return (
        <OnVisible className="quote">
          <div className="inner-square">{decodeEntities(quote)}</div>
        </OnVisible>
      );
    case "video":
      return (
        <div className="page-header-video" dangerouslySetInnerHTML={{ __html: videoUrl }} />
      );
    case "graphic":
      return <OnVisible className="page-header-image"><Image image={graphic} /></OnVisible>;
    default:
      return null;
  }
}

export const PageHeader = (props) => {
  const {
    wordpressUrl,
    parent,
    type,
    title,
    subtitle,
    headline,
    bodyText,
    graphic,
    quote,
    videoUrl,
    hasButton,
    buttonLabel,
    buttonLink
  } = props;
  return (
    <section className={`page-header ${type}`}>
      <div className="top">
        <div className="breadcrumbs">
          <div className="wrap">
            <Link className="crumb" to="/" aria-label="Home">Home</Link> /
            {parent && (
              <>
                <Link className="crumb" to={`/${parent.slug}/`} aria-label={parent.title}> {decodeEntities(parent.title)}</Link> /
              </>
            )}
            <span className="crumb"> {decodeEntities(title)}</span>
          </div>
        </div>
        <div className="title">
          <div className="wrap"><h1>{decodeEntities(title)}</h1></div>
        </div>
      </div>
      <div className="wrap">
        <div className="subtitle">
          <p>{decodeEntities(subtitle)}</p>
        </div>
        <div className="columns">
          <div className="left">
            {type !== 'plain' && (
              <div className="accent">
                {getAccentPiece(type, graphic, quote, videoUrl)}
              </div>
            )}
          </div>
          <div className="right">
            <h2 className="headline">{decodeEntities(headline)}</h2>
            <div className="text">
              <div dangerouslySetInnerHTML={{__html: bodyText}} />
              {hasButton && (
                <Link className="styled-button" to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>
                  <span>{decodeEntities(buttonLabel)}</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
