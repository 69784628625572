import React, { Component } from 'react';
import { Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './TextBlock.scss';

// https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page

function scrollTo(elementY, duration) {
  const startingY = window.pageYOffset;
  const diff = elementY - startingY;
  let start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  })
}

export class TextBlock extends Component {

  getButton = (type, buttonLabel, buttonVal) => {
    const { wordpressUrl } = this.props;
    switch(type) {
      case 'internal-link':
        return (
          <Link className="button styled-button" to={getPageSlugFromWpLink(buttonVal, wordpressUrl)} aria-label={buttonLabel}>
            <span>{decodeEntities(buttonLabel)}</span>
          </Link>
        );
      case 'external-link':
        return (
          <a className="button styled-button" href={buttonVal} target="_blank" rel="noopener noreferrer">
            <span>{decodeEntities(buttonLabel)}</span>
          </a>
        );
      case 'file':
        return (
          <a className="button styled-button" href={buttonVal} target="_blank" rel="noopener noreferrer">
            <span>{decodeEntities(buttonLabel)}</span>
          </a>
        )
      case 'hash':
        return (
          <a
            className="button styled-button"
            href={buttonVal}
            onClick={(e) => this.scrollToTarget(e, buttonVal)}
          >
            <span>{decodeEntities(buttonLabel)}</span>
          </a>
        );
      default:
        return null;
    }
  }

  getButtonVal = () => {
    const {
      buttonType,
      file,
      internalLink,
      externalLink,
      hash
    } = this.props;
    switch(buttonType) {
      case 'internal-link':
        return internalLink;
      case 'external-link':
        return externalLink;
      case 'file':
        return file.source_url;
      case 'hash':
        return `#${hash}`;
      default:
        return null;
    }
  }

  scrollToTarget = (event, target) => {
    event.preventDefault();
    const targetEl = document.querySelector(`${target}`);
    scrollTo(targetEl.offsetTop, 400);
  }

  render() {
    const {
      // wordpressUrl,
      title,
      titleIsPageHeading = false,
      format,
      columnOneText,
      columnTwoText,
      hasButton,
      buttonType,
      buttonLabel,
      // file,
      // internalLink,
      // externalLink,
      // hash,
    } = this.props;
    const buttonVal = this.getButtonVal();
    return (
      <section className={`text-block ${format}`}>
        <div className="wrap">
          {titleIsPageHeading ?
            <h1 className="title" dangerouslySetInnerHTML={{__html: title}} /> :
            <h2 className="title" dangerouslySetInnerHTML={{__html: title}} />
          }
          <div className="text-columns">
            {(format === 'full-width' || format === 'two-col') && (
              <div className="col col-1">
                <div dangerouslySetInnerHTML={{__html: columnOneText}} />
                {(columnOneText && !columnTwoText && hasButton) && (
                  this.getButton(buttonType, buttonLabel, buttonVal)
                )}
              </div>
            )}
            {format === 'two-col' && (
              <div className="col col-2">
                <div dangerouslySetInnerHTML={{__html: columnTwoText}} />
                {(columnTwoText && hasButton) && (
                  this.getButton(buttonType, buttonLabel, buttonVal)
                )}
              </div>
            )}
          </div>

        </div>
      </section>
    );
  }
}
