import React from 'react';
import './ArticleTextBlock.scss';

export const ArticleTextBlock = (props) => {
  const {
    numColumns,
    columnOneText,
    columnTwoText
  } = props;
  return (
    <section className={`article-text-block ${numColumns}`}>
      <div className="wrap">
        <div className="columns">
          <div className="left-col col" dangerouslySetInnerHTML={{__html: columnOneText}} />
          {numColumns === 'two' && (
            <div className="right-col col" dangerouslySetInnerHTML={{__html: columnTwoText}} />
          )}
        </div>
      </div>
    </section>
  );
}
