import React from 'react';
import { Link } from 'gatsby';
import Carousel from '../components/Carousel';
import Image from '../components/Image';
import { HTMLContent } from '../components/Content';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './HomeSlider.scss'

export const HomeSlider = (props) => {
  const { id, slides, wordpressUrl } = props;
  return (
    <section className="home-slider">
      <div className="home-slider-slides">
        <Carousel
          idContainer={id ? `${id.split("").reverse().join("")}` : null}
          loop
          autoplay
          nav
          autoplayTimeout={8000}
          controls={false}
        >
          { slides && slides.map((slide, index) => {
            const {
              title,
              subtitle,
              image: etchedImage,
              hasButton,
              buttonLink,
              buttonLabel,
            } = slide;
            return (
              <div className="slide" key={index}>
                <div className="wrap">
                  <div className="image-col">
                    <Image image={etchedImage} className="contain top-aligned" />
                  </div>
                  <div className="text-col">
                    { index === 0 ? <h1 className="title">{decodeEntities(title)}</h1> : <h2 className="title">{decodeEntities(title)}</h2> }
                    <HTMLContent className="subtitle" content={subtitle} />
                    { hasButton && (
                      <Link className="styled-button" aria-label={buttonLabel} to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>
                        <span>{decodeEntities(buttonLabel)}</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}
