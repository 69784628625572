import React from 'react';
import { Link } from 'gatsby';
import OnVisible from 'react-on-visible';
import Image from '../components/Image';
import { HTMLContent } from '../components/Content';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './CallToAction.scss'
import './CallToActionPair.scss'

const getButtonTheme = (theme) => {
  if (theme === 'primary') return 'light';
  return '';
}

export const CallToActionPair = (props) => {
  const { callToActions, wordpressUrl } = props;
  if (!callToActions) return null;
  return (
    <section className="call-to-action-pair">
      <div className="call-to-actions">
        {callToActions.map(callToAction => {
          const {
            theme,
            graphic,
            graphicPosition,
            title,
            headline,
            bodyText,
            hasButton,
            buttonLink,
            buttonLabel,
          } = callToAction;
          return (
            <div className={`call-to-action ${theme}`} key={title}>
              <OnVisible className="outer-container">
                <span className="title">{decodeEntities(title)}</span>
                <div className="inner-container">
                  {graphicPosition.indexOf('bottom') === -1 && (
                    <div className={`graphic ${graphicPosition}`}>
                      <Image image={graphic} />
                    </div>
                  )}
                  <h2 className="headline">{decodeEntities(headline)}</h2>
                  <HTMLContent className="body-text" content={bodyText} />
                  { hasButton && (
                    <Link className={`styled-button ${getButtonTheme(theme)}`} aria-label={buttonLabel} to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>
                      <span>{decodeEntities(buttonLabel)}</span>
                    </Link>
                  )}
                  {graphicPosition.indexOf('bottom') !== -1 && (
                    <OnVisible className={`graphic ${graphicPosition}`}>
                      <Image image={graphic} />
                    </OnVisible>
                  )}
                </div>
              </OnVisible>
            </div>
          );
        })}
      </div>
    </section>
  )
}
