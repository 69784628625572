import React, { Component } from 'react';
import './Plyr.scss';

const isClient = typeof window !== 'undefined';
const plyr = isClient ? require('plyr') : null;

export default class Plyr extends Component {
  componentDidMount() {
    const options = {
      playsinline: true,
    };
    this.player = plyr.setup('#plyr-player', options);
  }

  componentWillUnmount() {
    if (this.player && this.player.length > 0) {
      for (const playerEl of this.player) {
        playerEl.destroy();
      }
    }
  }

  render() {
    const { videoUrl, videoProvider } = this.props;
    return (
      <div
        id="plyr-player"
        data-plyr-provider={videoProvider}
        data-plyr-embed-id={videoUrl}
      />
    );
  }
}
