import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import OnVisible from 'react-on-visible';
import Carousel from './Carousel';
import PostList from './PostList';
import { PostListItem } from './PostListItem';
import { decodeEntities } from '../utils/htmlParse';
import './PostBlock.scss';

class PostBlock extends Component {
  state = { offsetLeft: null, minHeight: null, slider: null };

  componentDidMount() {
    const { isSlider } = this.props;
    if (isSlider) {
      this.updateOffsetLeft();
      window.addEventListener('resize', this.updateOffsetLeft);
    }
    if (!isSlider) {
      this.getEqualHeightVal();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { slider: oldSlider } = prevState;
    const { slider: newSlider } = this.state;
    if (!oldSlider && newSlider) {
      this.getEqualHeightVal();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateOffsetLeft);
  }

  updateOffsetLeft = () => {
    const elementResized = document.querySelector('.slider-wrap-offset');
    this.setState({ offsetLeft: elementResized.offsetLeft + 17 });
  }

  getEqualHeightVal = () => {
    const containers = document.querySelectorAll(".post-block-slider-container .post .container");
    const heights = Array.prototype.slice.call(containers).map(panel => panel.offsetHeight);
    const sortedHeights = heights.sort((a, b) => { return a > b ? -1 : 1});
    this.setState({ minHeight: sortedHeights[0] });
  }

  initSlider = (slider) => {
    this.setState({ slider });
  }

  goToSlide = (event, direction) => {
    event.preventDefault();
    const { slider } = this.state;
    slider.goTo(direction);
  }

  render() {
    const { posts, isSlider, title, data, theme, buttonText, buttonUrl, pathPrefix = null, sliderContainer = 'post-block-slider' } = this.props;
    const { offsetLeft, slider, minHeight } = this.state;
    const { site } = data;
    const { siteMetadata } = site;
    const { blogSlug } = siteMetadata;
    if (isSlider) {
      return (
        <section className={`post-block post-block-slider-container ${theme || ''}`}>
          <div className="wrap slider-wrap-offset">
            {title && (
              <div className="post-block-title">
                <h3>{decodeEntities(title)}</h3>
                {slider && (
                  <div className="slider-nav">
                    <button className="styled-button slider-nav-button slider-nav-button-prev" onClick={event => this.goToSlide(event, 'prev')} aria-label="Previous" type="button">
                      <span>Prev</span>
                    </button>
                    <button className="styled-button slider-nav-button slider-nav-button-next" onClick={event => this.goToSlide(event, 'next')} aria-label="Next" type="button">
                      <span>Next</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {offsetLeft !== null && (
            <div style={{ paddingLeft: offsetLeft }}>
              <Carousel
                items={3}
                container={sliderContainer}
                autoWidth
                nav={false}
                controls={false}
                gutter={20}
                onInit={this.initSlider}
                arrowKeys
              >
                {posts.map((post, index) => <PostListItem key={index} post={post.node || post} pathPrefix={pathPrefix || blogSlug} minHeight={minHeight} />)}
                <div style={{ width: offsetLeft - 17 }} />
              </Carousel>
            </div>
          )}
          {buttonText && (
            <div className="wrap">
              <OnVisible className="slider-button-container">
                <Link className="more-insights styled-button" to={`/${buttonUrl}/` || '/'} aria-label={buttonText}><span>{decodeEntities(buttonText)}</span></Link>
              </OnVisible>
            </div>
          )}
        </section>
      )
    }
    return (
      <section className="post-block post-block-stacked-container">
        <div className="wrap">
          {title && (
            <div className="post-block-title">
              <h3>Ideas & Insights</h3>
              {buttonText && <Link className="styled-button" to={`/${buttonUrl}/` || '/'} aria-label={buttonText}><span>{decodeEntities(buttonText)}</span></Link>}
            </div>
          )}
          <PostList posts={posts} pathPrefix={blogSlug} minHeight={minHeight} hideFilters />
        </div>
      </section>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            blogSlug
          }
        }
      }
    `}
    render={data => <PostBlock data={data} {...props} />}
  />
)
