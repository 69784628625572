import React, { Component } from 'react';
import Carousel from '../components/Carousel';
import { GridBlock } from './GridBlock';
import './GridSliderBlock.scss'

export class GridSliderBlock extends Component {
  state = {
    slider: {},
    currentIndex: 1
  };

  onSliderInitHandler = (slider) => {
    if (slider) this.setState({ slider });
  }

  onSlideChangeHandler = (slide) => {
    if (slide) this.setState({ currentIndex: slide.displayIndex })
  }

  render() {
    const { slider, currentIndex } = this.state;
    const {
      id,
      slides,
      wordpressUrl
    } = this.props;
    if (!slides) return null;
    return (
      <section className="grid-slider-block">
        <div className="tns-nav" aria-label="Carousel Pagination">
          {slides.map((slide, index) => {
            const isActive = index + 1 === currentIndex;
            return (
              <button
                data-nav={index}
                type="button"
                key={index + 1}
                aria-label={`Carousel Page ${index + 1}${isActive ? ' (Current Slide)' : ''}`}
                className={isActive ? 'tns-nav-active' : ''}
                onClick={() => slider.goTo(index)}
              />
            )
          })}
          <span className="current-slide-display">{currentIndex} / {slides.length}</span>
        </div>
        <Carousel
          idContainer={id ? `${id.split("").reverse().join("")}` : null}
          nav={false}
          controls={false}
          onInit={this.onSliderInitHandler}
          onChange={this.onSlideChangeHandler}
          mode="gallery"
          currentSlide={currentIndex}
          totalSlides={slides.length}
          autoplay
        >
          {slides.map(slide => <GridBlock wordpressUrl={wordpressUrl} {...slide} key={slide.title} />)}
        </Carousel>
      </section>
    )
  }
}
