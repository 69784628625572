import React from 'react';
import { Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './PageNavigationBlock.scss';

export const PageNavigationBlock = (props) => {
  const {
    leftLink,
    rightLink,
    wordpressUrl
  } = props;
  return (
    <section className="page-navigation-block">
      <div className="wrap">
        <div className={`left-link ${leftLink ? 'visible' : ''}`}>
          <Link to={`${getPageSlugFromWpLink(leftLink.link, wordpressUrl)}`} aria-label={leftLink.linkHeadline}>
            <span className="link-label">{decodeEntities(leftLink.linkLabel)}</span>
            {leftLink.linkHeadline && <span className="headline" dangerouslySetInnerHTML={{__html: leftLink.linkHeadline}} />}
            {leftLink.linkSubtitle && <span className="subtitle" dangerouslySetInnerHTML={{__html: leftLink.linkSubtitle}} />}
          </Link>
        </div>
        <div className={`right-link ${rightLink ? 'visible' : ''}`}>
          <Link to={`${getPageSlugFromWpLink(rightLink.link, wordpressUrl)}`} aria-label={rightLink.linkHeadline}>
            <span className="link-label">{decodeEntities(rightLink.linkLabel)}</span>
            {rightLink.linkHeadline && <span className="headline" dangerouslySetInnerHTML={{__html: rightLink.linkHeadline}} />}
            {rightLink.linkSubtitle && <span className="subtitle" dangerouslySetInnerHTML={{__html: rightLink.linkSubtitle}} />}
          </Link>
        </div>
      </div>
    </section>
  );
}
