import React, { Component } from 'react';
import RiskAssessmentQuiz from '../components/Quizzes/RiskAssessmentQuiz';
import { decodeEntities } from '../utils/htmlParse';
import './QuizBlock.scss';

const getQuiz = (quiz, quizResults, siteUrl) => {
  switch (quiz) {
    case 'risk-assessment':
      return <RiskAssessmentQuiz quizResults={quizResults} siteUrl={siteUrl} />
    default:
      return null;
  }
}

export class QuizBlock extends Component {
  state = {
    quizStarted: false
  }

  startQuiz = () => {
    this.setState({ quizStarted: true });
    window.scrollTo(0,0);
  }

  render() {
    const {
      quiz,
      hasIntroPage,
      quizIntro,
      startQuizButtonLabel,
      quizResults,
      siteUrl
    } = this.props;
    const { quizStarted } = this.state;
    return (
      <section className="quiz-block">
        {(hasIntroPage && !quizStarted) ? (
          <div className="quiz-intro">
            <div className="wrap">
              <div className="quiz-intro-content" dangerouslySetInnerHTML={{__html: quizIntro}} />
              <button
                className="styled-button start-button"
                onClick={this.startQuiz}
                aria-label="Start"
                type="button"
              >
                <span>{decodeEntities(startQuizButtonLabel) || 'Start'}</span>
              </button>
            </div>
          </div>
        ) : (
          getQuiz(quiz, quizResults, siteUrl)
        )}
      </section>
    );
  }
}
