import React from 'react';
import * as Forms from '../components/Forms';
import { decodeEntities } from '../utils/htmlParse';
import './FormBlock.scss';

const getForm = (form, siteUrl) => {
  switch(form) {
    case 'contact':
      return <Forms.ContactForm siteUrl={siteUrl} />;
    case 'wealth-service':
      return <Forms.ServiceEnquiryForm siteUrl={siteUrl} serviceType={form} />;
    case 'specialist':
      return <Forms.SpecialistContactForm siteUrl={siteUrl} />;
    case 'investment':
      return <Forms.InvestmentPlanContactForm siteUrl={siteUrl} />;
    default:
      return null;
  }
}

export const FormBlock = (props) => {
  const {
    title,
    headline,
    subtitle,
    form,
    siteUrl,
  } = props;
  return (
    <section className="form-block">
      <div className="wrap" id="form">
        <h2 className="title">{decodeEntities(title)}</h2>
        <div className="form-box">
          <h3 className="headline">{decodeEntities(headline)}</h3>
          <p className="subtitle">{decodeEntities(subtitle)}</p>
          {getForm(form, siteUrl)}
        </div>
      </div>
    </section>
  );
}
