import React from 'react'
import { graphql } from 'gatsby'
import { CategoryFilter } from './CategoryFilter';
import { PostListItem } from './PostListItem';
import Pagination from './Pagination';
import './PostList.scss';

export default class PostList extends React.Component {
  render() {

    const { posts, pathPrefix, activeFilter, pageContext, categories, title, minHeight } = this.props;
    return (
      <section className="post-list">
        {categories && (
          <div className="filter-select-container">
            <CategoryFilter pathPrefix={pathPrefix} categories={categories} activeFilter={activeFilter} />
          </div>
        )}
        {title && <h1 className="title" dangerouslySetInnerHTML={{__html: title}} />}
        <div className="posts">
          {posts.map(({ node: post }) => <PostListItem post={post} pathPrefix={pathPrefix} minHeight={minHeight} />)}
        </div>
        <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
      </section>
    )
  }
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    featuredImage: featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    categories {
      id
      name
      slug
    }
    author {
      name
    }
    title
    excerpt
    date(formatString: "DD.MM.YYYY")
    slug
  }
`
