import React from 'react';
import { Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './LinksListBlock.scss';

export const LinksListBlock = props => {
  const {
    title,
    subtitle,
    links,
    wordpressUrl
  } = props;
  return (
    <section className="links-list">
      {(title || subtitle) && (
        <div className="top-block">
          <div className="wrap">
            <div className="inner-wrap">
              <h2 className="title">{decodeEntities(title)}</h2>
              <p className="subtitle">{decodeEntities(subtitle)}</p>
            </div>
          </div>
        </div>
      )}
      <div className="bottom-block">
        <div className="wrap">
          <div className="wrap-inner">
            <div className="links">
              {links.map((link) => (
                <div className="link">
                  <span>{decodeEntities(link.label)}</span>
                  {link.linkType === 'url' && <a className="inner-link" href={link.url} target="_blank" rel="noopener noreferrer">{decodeEntities(link.title)}</a>}
                  {link.linkType === 'page-link' && <Link className="inner-link" to={getPageSlugFromWpLink(link.pageLink, wordpressUrl)}>{decodeEntities(link.title)}</Link>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
