import React from 'react';
import { Link } from 'gatsby';
import Image from './Image';
import { decodeEntities } from '../utils/htmlParse';
import './PostListItem.scss';

export const PostListItem = props => {
  const { post, pathPrefix, minHeight } = props;
  const postSlug = `/${pathPrefix}/${post.slug}`;
  return (
    <div className="post">
      <div className="meta">
        {/* eslint-disable-next-line */}
        <p>{post.categories && `${decodeEntities(post.categories[0].name)} / `} {post.date}</p>
        {/* eslint-disable-next-line */}
        {post.author && <p className="author">By {decodeEntities(post.author.name)}</p>}
      </div>
      <div className="container" style={{ minHeight }}>
        <Link className="featured-image" to={`/${postSlug}/`} aria-label={post.featureImage}>
          <Image image={post.featuredImage} />
        </Link>
        <h2 className="title"><Link className="animated-underline" to={`/${postSlug}/`} aria-label={post.title}>{decodeEntities(post.title)}</Link></h2>
        <div className="excerpt" dangerouslySetInnerHTML={{__html: post.excerpt}} />
      </div>
    </div>
  );
};
