import { decodeEntities, stripHtmlTags } from './htmlParse';

export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null;
  return wpLink.replace(wordPressUrl, '');
}
export const getExcerpt = (str, length = 115) => {
  const strippedStr = stripHtmlTags(str);
  const decodedStr = decodeEntities(strippedStr);
  const dots = decodedStr.length > length ? '...' : '';
  return `${decodedStr.substring(0, length)}${dots}`;
}
