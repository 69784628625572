import React from 'react';
import { decodeEntities } from '../utils/htmlParse';
import './ListBlock.scss';

export const ListBlock = (props) => {
  const {
    hasLogo,
    logo,
    title,
    listItems
  } = props;
  return (
    <section className="list-block">
      <div className="wrap">
        <div className="list-container">
          {hasLogo && logo && (
            <div className="logo">
              <img src={logo.logoUrl} alt='' />
            </div>
          )}
          <h2 className="title">{decodeEntities(title)}</h2>
          {listItems && (
            <div className="list-items">
              {listItems.map(listItem => (
                <div className="list-item">
                  <p className="text">{decodeEntities(listItem.text)}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
