import React, { Component } from 'react';
import { Link } from 'gatsby';
import { stripHtmlTags, decodeEntities } from '../utils/htmlParse';
import Image from '../components/Image';
import VideoModalButton from '../components/VideoModalButton';
import { SocialLink } from '../components/SocialLink';
import './PageHeaderShort.scss';
import './ArticleIntro.scss';

const capitalizePrefix = (prefix) => {
  return prefix.charAt(0).toUpperCase() + prefix.substr(1);
}

const Author = props => {
  const { author, showBio, category, date, pathPrefix } = props;
  if (!author) return null;
  return (
    <div className="author-box">
      <img className="author-image" src={author.avatar_urls && author.avatar_urls.wordpress_96} alt={author.name} />
      <div className="author-meta">
        <div className="author-name">
          {decodeEntities(author.name)}
          {author.meta && (
            <div className="author-socials">
              {author.meta.twitter.length > 0 && (
                <SocialLink
                  socialLink={{type: 'twitter', url: author.meta.twitter}}
                  theme="dark"
                />
              )}
              {author.meta.googleplus.length > 0 && (
                <SocialLink
                  socialLink={{type: 'linkedin', url: author.meta.googleplus}}
                  theme="dark"
                />
              )}
            </div>
          )}
        </div>
        {date && category && (
          <div className="author-posted-on">
            Posted {date} in&nbsp;
            {category && (
              <span className="category-link">
                <Link to={`/${pathPrefix}/category/${category.slug}/`}>{decodeEntities(category.name)}</Link>
              </span>
            )}
          </div>
        )}
        {showBio && (
          <blockquote className="author-bio">{author.description}</blockquote>
        )}
      </div>

    </div>
  )
}

export class ArticleIntro extends Component {
  state = { stickSocials: false, fixedSocials: false };

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  scrollListener = () => {
    const { stickSocials, fixedSocials } = this.state;
    const containerElement = document.getElementById('post-body');
    const socialLinksElement = document.getElementById('social-links');
    const { top, bottom } = containerElement.getBoundingClientRect();
    const stick = top <= 0;
    const fixSocials = bottom < socialLinksElement.clientHeight;
    if (stick && !fixSocials && !stickSocials) {
      this.setState({ stickSocials: true, fixedSocials: false });
    }
    if (!stick && stickSocials) {
      this.setState({ stickSocials: false, fixedSocials: false });
    }
    if (fixSocials && !fixedSocials) {
      this.setState({ stickSocials: false, fixedSocials: true });
    }
  }

  render() {
    const {
      siteUrl,
      post,
      pathPrefix = 'blog',
      location,
      image,
      subtitle,
      bodyText,
      videoProvider,
      videoUrl,
      wordpressUrl,
    } = this.props;
    const { stickSocials, fixedSocials } = this.state;
    return (
      <section className="article-intro-block">
        <section className="page-header-short">
          <div className="top">
            <div className="breadcrumbs">
              <div className="wrap">
                <Link className="crumb" aria-label="Home" to="/">Home</Link> /
                <Link className="crumb" aria-label={capitalizePrefix(pathPrefix)} to={`/${pathPrefix}/`}> {capitalizePrefix(pathPrefix)}</Link> /
                <span className="crumb" aria-label={post.title} dangerouslySetInnerHTML={{__html: ` ${post.title}`}} />
              </div>
            </div>
            <div className="title">
              <div className="wrap"><span>Knowledge Hub</span></div>
            </div>
          </div>
          <div className="wrap">
            <div className="subtitle">
              <Link className="back-link" to={`/${pathPrefix}/`}>Back to list</Link>
            </div>
          </div>
        </section>
        <div className="intro">
          <div className={`social-links ${stickSocials ? 'stick' : 'unstick'} ${fixedSocials ? 'stuck' : 'unstuck'}`} id="social-links">
            <div className="link">
              <SocialLink
                socialLink={{type: 'facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${location.href}`}}
                theme="dark"
              />
            </div>
            <div className="link">
              <SocialLink
                socialLink={{type: 'twitter', url: `https://twitter.com/intent/tweet/?text=${`${post.title} - `}&url=${location.href}`}}
                theme="dark"
              />
            </div>
            <div className="link">
              <SocialLink
                socialLink={{type: 'linkedin', url: `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${post.title}&summary=&source=${siteUrl}`}}
                theme="dark"
              />
            </div>
            <div className="link">
              <SocialLink
                socialLink={{type: 'google_plus', url: `https://plus.google.com/share?url=${location.href}`}}
                theme="dark"
              />
            </div>
            {pathPrefix === 'blog' && (
              <div className="link">
                <a
                  href={`${wordpressUrl}/${pathPrefix}/${post.slug}?pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <path fillRule="nonzero" d="M85.97 22.092H73V3.162A3.162 3.162 0 0 0 69.838 0H20.162A3.162 3.162 0 0 0 17 3.162v18.93H4.039A4.05 4.05 0 0 0 0 26.131v37.872a4.03 4.03 0 0 0 4.03 4.03H17v18.805A3.162 3.162 0 0 0 20.162 90h49.676A3.162 3.162 0 0 0 73 86.838V68.033h12.97a4.03 4.03 0 0 0 4.03-4.03V26.122a4.03 4.03 0 0 0-4.03-4.03zM69 86H21V53.055h48V86zm0-63.908H21V4h48v18.092zm7.333 17.334a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7.334z" />
                  </svg>
                </a>
              </div>
            )}
          </div>
          <div className="container">
            <div className="left" id="post-body">
              <div className="wrap">
                <div className="text-container">
                  <h1 className="post-title" dangerouslySetInnerHTML={{__html: post.title}} />
                  {pathPrefix !== 'publications' && (
                    <Author author={post.author} category={post.categories && post.categories[0]} date={post.date} pathPrefix={pathPrefix} />
                  )}
                  <div className="post-image">
                    <Image image={image} />
                  </div>
                  <div className="body-text">
                    <div className="post-subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
                    {/* <div className="first-letter">{stripHtmlTags(bodyText).slice(0,1)}</div> */}
                    <div className="text" dangerouslySetInnerHTML={{__html: bodyText}} />
                  </div>
                  {videoUrl && videoProvider && (
                    <VideoModalButton
                      videoUrl={videoUrl}
                      videoProvider={videoProvider}
                    />
                  )}
                  {pathPrefix !== 'publications' && (
                    <Author author={post.author} showBio />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
