import React from 'react';
import Image from '../components/Image';
import './TeamMemberGridBlock.scss';

export const TeamMemberGridBlock = (props) => {
  const {
    orientation,
    image,
    bodyText
  } = props;
  return (
    <section className={`team-member-grid-block ${orientation} ${image ? '' : 'no-image'}`}>
      <div className="wrap">
        {image && (
          <div className="left-col">
            <div className="image">
              <Image image={image} />
            </div>
          </div>
        )}
        <div className="right-col">
          <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />
        </div>
      </div>
    </section>
  );
}
