import React from 'react';
import { Link } from 'gatsby';
import OnVisible from 'react-on-visible';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './CallToAction.scss';

export const CallToAction = (props) => {
  const {
    theme,
    type,
    title,
    headline,
    bodyText,
    hasButton,
    buttonLabel,
    buttonLink,
    buttonDownload,
    externalButtonLink,
    quoteeTitle,
    quoteeName,
    wordpressUrl
  } = props;
  return (
    <section className={`call-to-action ${theme} ${type}`}>
      <div className="wrap">
        {title && (
          <div className="title">
            <h2>{decodeEntities(title)}</h2>
          </div>
        )}
        <div className="box">
          {headline && <span className="headline">{decodeEntities(headline)}</span>}
          {type === 'quote' && (
            <>
              {quoteeTitle && <OnVisible className="quotee-title">{decodeEntities(quoteeTitle)}</OnVisible>}
              {quoteeName && <OnVisible className="quotee-name">{decodeEntities(quoteeName)}</OnVisible>}
            </>
          )}
          {type === 'link' && (
            <>
              {bodyText && <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />}
              {hasButton && (
                <OnVisible className="call-to-action-button-container">
                  <Link
                    className={`styled-button ${theme === 'primary' ? 'light' : ''}`}
                    to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}
                    aria-label={buttonLabel}
                  >
                    <span>{decodeEntities(buttonLabel)}</span>
                  </Link>
                </OnVisible>
              )}
            </>
          )}
          {type === 'download' && (
            <>
              {hasButton && (
                <OnVisible className="call-to-action-button-container">
                  <a
                    className={`styled-button ${theme === 'primary' ? 'light' : ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={buttonDownload.link}
                    aria-label={buttonLabel}
                  >
                    <span>{decodeEntities(buttonLabel)}</span>
                  </a>
                </OnVisible>
              )}
            </>
          )}
          {type === 'external-link' && (
            <>
              {bodyText && <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />}
              {hasButton && (
                <OnVisible className="call-to-action-button-container">
                  <a
                    className={`styled-button ${theme === 'primary' ? 'light' : ''}`}
                    href={externalButtonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={buttonLabel}
                  >
                    <span>{decodeEntities(buttonLabel)}</span>
                  </a>
                </OnVisible>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}
