import React from 'react';
import { PdfIcon } from '../components/PdfIcon';
import { decodeEntities } from '../utils/htmlParse';
import './ThreeColumnLinksList.scss';

export const ThreeColumnLinksList = (props) => {
  const {
    linkGroups
  } = props;
  return (
    <section className="three-column-links-list">
      <div className="wrap">
        {linkGroups && linkGroups.map((group) => {
          return (
            <div className="group">
              <h3 className="title" dangerouslySetInnerHTML={{__html: group.title}} />
              {group.links && (
                <div className="links">
                  {group.links.map((link) => {
                    const { pdfUrl, externalUrl, title } = link;
                    return (
                      <div className="link">
                        <div className="pdf-icon"><PdfIcon color="#004F6E" width="18" /></div>
                        <a href={pdfUrl ? pdfUrl.url : externalUrl} target="_blank" rel="noopener noreferrer">{decodeEntities(title) || (pdfUrl ? decodeEntities(pdfUrl.url) : decodeEntities(externalUrl))}</a>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}
