import React from 'react';
import OnVisible from 'react-on-visible';
import { decodeEntities } from '../utils/htmlParse';
// import Image from '../components/Image';
import './DiagramBlock.scss';

const getStepNumber = (index) => {
  const trueIndex = index + 1;
  return trueIndex < 10 ? `0${trueIndex}` : trueIndex;
}

export const DiagramBlock = (props) => {
  const {
    id,
    hasTitle,
    title,
    hasLogo,
    logo,
    steps,
    hasOutcome,
    outcome,
    outcomeSubtitle,
    headline,
    bodyTextColumns,
  } = props;
  return (
    <section className={`diagram-block ${hasOutcome ? 'has-outcome' : ''}`}>
      <div className="wrap">
        {hasTitle && (
          <div className="title">
            <h2>{decodeEntities(title)}</h2>
          </div>
        )}
        {hasLogo && (
          <div className="logo-block">
            <img src={logo.url.source_url} alt='' />
          </div>
        )}
        {steps && (
          <OnVisible className="steps-wrapper" percent={25}>
            <div className="steps">
              {steps.map((step, index) => {
                return (
                  <div className="step">
                    <div className="step-text">
                      <span className="number" style={{transitionDelay: `${(index + 1) * .1}s`}}>{getStepNumber(index)}</span>
                      <p className="text">{decodeEntities(step.stepText)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {hasOutcome && (
              <div className="outcome">
                <div className="subtitle"><p>{decodeEntities(outcomeSubtitle)}</p></div>
                <div className="text"><span>{decodeEntities(outcome)}</span></div>
                <span className="flex-filler" />
              </div>
            )}
          </OnVisible>
        )}
        {(headline || bodyTextColumns) && (
          <div className="diagram-text">
            {headline &&
              <div className="headline"><h2>{decodeEntities(headline)}</h2></div>
            }
            {bodyTextColumns && (
              <div className="columns">
                {bodyTextColumns.map(col => (
                  <div className="column" dangerouslySetInnerHTML={{__html: col.bodyTextColumn}} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
