import React, { Component } from 'react';
import 'tiny-slider/src/tiny-slider.scss';
import './Carousel.scss'

export default class Carousel extends Component {
  componentDidMount() {
    this.initSlider(this.props);
  }

  initSlider = (props) => {
    const {
      autoplay = false,
      autoplayTimeout = 5000,
      autoplayButtonOutput = false,
      idContainer = null,
      container = 'carousel-slider',
      items = 1,
      nav = true,
      responsive,
      controls = true,
      mouseDrag = true,
      loop = false,
      center = false,
      arrowKeys = false,
      autoWidth = false,
      edgePadding = 0,
      gutter = 0,
      mode = 'carousel',
      onChange = () => {},
      onInit
    } = props;
    try {
      // eslint-disable-next-line
      const { tns } = require('tiny-slider/src/tiny-slider.module.js');
      const carousel = tns({
        container: idContainer ? `#${idContainer}` : `.${container}`,
        items,
        autoplay,
        autoplayTimeout,
        autoplayButtonOutput,
        nav,
        center,
        controls,
        mouseDrag,
        loop,
        mode,
        arrowKeys,
        autoWidth,
        gutter,
        responsive,
        edgePadding
      });
      carousel.events.on('indexChanged', onChange);
      if (onInit) onInit(carousel);
    } catch (error) {
      console.error('Error firing slider', error);
    }
  }

  render() {
    const { idContainer, container = 'carousel-slider', children } = this.props;
    return (
      <section id={idContainer} className={container}>
        {children}
      </section>
    );
  }
}
