import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Image from '../components/Image';
import { SearchIcon } from '../components/SearchIcon';
import { decodeEntities } from '../utils/htmlParse';
import './TeamGlossary.scss';

class TeamGlossaryTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFilter: 'all',
      searchActive: false,
      activeTeamMembers: props.teamMembers.edges,
      showFilters: false
    }
  }

  showFiltersOnMobile = () => {
    const { showFilters } = this.state;
    return this.setState({ showFilters: !showFilters });
  }

  toggleSearchBar = () => {
    const { searchActive } = this.state;
    return this.setState({ searchActive: !searchActive });
  }

  filterTeamMembers = (event, id) => {
    // Get all team members from props
    const { teamMembers } = this.props;

    // If user selected a filter
    if (id !== null) {
      // Filter them using the filter clicked
      const activeTeamMembers = teamMembers.edges.filter(({node: member}) => {
        if (id === 'all') return true;
        if (member.types.length === 0 && id !== 'all') return false;
        return member.types[0] === id;
      });
      // Set active fliter and new active team members in component state
      return this.setState({
        activeFilter: id,
        activeTeamMembers,
        showFilters: false
      });
    }

    // If user is searching, get the search term value
    const searchTerm = event.target.value.toLowerCase();
    // Filter them using the filter clicked
    const activeTeamMembers = teamMembers.edges.filter(({node: member}) => {
      return member.name.toLowerCase().includes(searchTerm);
    });
    // Set active filter and new active team members in component state
    return this.setState({
      activeFilter: id,
      activeTeamMembers
    });
  }

  render() {
    const {
      teamMembers,
      teamMemberTypes
    } = this.props;
    const {
      activeFilter,
      searchActive,
      showFilters,
      activeTeamMembers
    } = this.state;
    return (
      <section className="team-glossary">
        {/* <div className="top-bar">
          <div className="wrap">
            <button
              type="button"
              className="search-toggle"
              onClick={() => this.toggleSearchBar()}
            >
              <SearchIcon strokeColor="#817B71" />
            </button>
            <input
              type="text"
              placeholder="Search by name"
              className={`search-bar ${searchActive ? 'active' : ''}`}
              onChange={(event) => this.filterTeamMembers(event, null)}
            />
            {teamMemberTypes && (
              <button
                type="button"
                className={`filter show-filters active ${showFilters ? 'open' : 'closed'}`}
                onClick={this.showFiltersOnMobile}
                aria-label="Filter team by"
              >
                Filter By: {teamMemberTypes && teamMemberTypes.edges.find(({node}) => activeFilter === node.id) && teamMemberTypes.edges.find(({node}) => activeFilter === node.id).node.name || 'All'}
              </button>
            )}
            {teamMemberTypes && (
              <button
                type="button"
                className={`filter view-all ${showFilters ? '' : 'small-tablet-up'} ${activeFilter === 'all' ? 'active' : ''}`}
                onClick={(event) => this.filterTeamMembers(event, 'all')}
                aria-label="View All"
              >
                View All
              </button>
            )}
            {teamMemberTypes && teamMemberTypes.edges.map(({node: type}) => {
              return (
                <button
                  key={type.id}
                  type="button"
                  className={`filter ${showFilters ? '' : 'small-tablet-up'} ${activeFilter === type.id ? 'active' : ''}`}
                  onClick={(event) => this.filterTeamMembers(event, type.id)}
                  aria-label={type.name}
                >
                  {type.name}
                </button>
              );
            })}
          </div>
        </div> */}
        <div className="wrap">
          {teamMembers && (
            <div className="team-members">
              {activeTeamMembers.length > 0 ?
                activeTeamMembers.map(({node: member}) => {
                  return (
                    <Link className="team-member animated-border" to={`/our-team/${member.slug}/`} key={member.id} aria-label={member.name}>
                      <div className="photo">
                        <Image image={member.acf.featureImage} className="cover" />
                      </div>
                      <div className="text">
                        <h2 className="name">{decodeEntities(member.name)}</h2>
                        <p className="role">{decodeEntities(member.acf.role)}</p>
                      </div>
                    </Link>
                  );
                })
              :
                <div>No team members found.</div>
              }
            </div>
          )}
        </div>
      </section>
    );
  }
}

export const TeamGlossary = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          teamMembers: allWordpressWpTeamMember {
            edges {
              node {
                id
                slug
                name: title
                types: team_member_type
                acf {
                  featureImage {
                    url: source_url
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1200, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  role
                }
              }
            }
          }
          teamMemberTypes: allWordpressWpTeamMemberType {
            edges {
              node {
                name
                slug
                id: wordpress_id
              }
            }
          }
        }
      `}
      render={data => <TeamGlossaryTemplate teamMembers={data.teamMembers} teamMemberTypes={data.teamMemberTypes} />}
    />
  );
}
