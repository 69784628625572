import React, { Component } from 'react';
import { ProgressBar } from '../ProgressBar';
import './RiskAssessmentQuiz.scss';

const FORMBUCKET_ENDPOINT = 'https://api.formbucket.com/f/buk_BANEU6SuLldKBLh4E2JGbFZz';

export default class RiskAssessmentQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
      scoreObj: {},
      invalidInputsObj: null,
      currentStage: 1,
      stageProgress: null,
      outputPdfUrl: '',
    }
  }

  // Checks the if the inputs on the current quiz page are valid
  allInputsValid = (activePage) => {
    // Get array of input on current active form page
    const inputs = document.querySelector(`.page-${activePage + 1}`).getElementsByTagName('input');
    // Check if all inputs are valid
    const allValid = Array.prototype.every.call(inputs, (input) => input.validity.valid);
    // If all valid, return true
    if (allValid) {
      this.setState({ invalidInputsObj: null });
      return true;
    }
    // If there are invalid fields, initialise an invalid inputs object
    const invalidInputsObj = {};
    // For every INVALID input, store the input name & native validation message
    // in invalidInputsObj using the name as the key
    Array.prototype.filter.call(inputs, (input) => !input.validity.valid)
      .forEach((input) => {
        invalidInputsObj[input.name] = input.validationMessage;
      });
    // Store invalidInputsObj in component state
    this.setState({ invalidInputsObj });
    return false;
  }

  // Checks whether a field with a specific fieldName needs fixing
  // RETURNS validation message element if fieldName exists in invalidInputsObj
  getValidationMessage = (fieldName) => {
    const { invalidInputsObj } = this.state;
    if (invalidInputsObj && Object.keys(invalidInputsObj).includes(fieldName)) {
      return <div className="validation-message">{invalidInputsObj[fieldName]}</div>
    }
    return null;
  }

  isValidField = (fieldName) => {
    const { invalidInputsObj } = this.state;
    const invalidFieldNames = invalidInputsObj ? Object.keys(invalidInputsObj) : [];
    return !invalidFieldNames.includes(fieldName);
  }

  updateScoreObj = (e) => {
    const { scoreObj } = this.state;
    scoreObj[e.target.name] = e.target.value;
    return this.setState({ scoreObj });
  }

  // Create quiz pages here
  getQuizPages = () => {
    return [
      <>
        <h3 className="title">Personal Information</h3>
        <div className="fields">
          <div className={`field ${!this.isValidField('name') ? 'error' : ''}`}>
            {this.getValidationMessage('name')}
            <input type="text" id="name" name="name" placeholder="Name" required />
          </div>
          <div className={`field ${!this.isValidField('email') ? 'error' : ''}`}>
            {this.getValidationMessage('email')}
            <input type="email" id="email" name="email" placeholder="Email" required />
          </div>
          <div className={`field ${!this.isValidField('phone') ? 'error' : ''}`}>
            {this.getValidationMessage('phone')}
            <input
              type="tel"
              pattern="^\+?\d{0,13}"
              id="phone"
              name="phone"
              placeholder="Phone (without spaces)"
              required
            />
          </div>
          <button
            type="button"
            className="styled-button next-button"
            onClick={this.gotoNextPage}
            aria-label="Next"
          >
            <span>Next</span>
          </button>
        </div>
      </>,
      <>
        <h3 className="title">Your Time Horizon</h3>
        <div className="fields">
          <div className={`field radio-group ${!this.isValidField('horizon-option') ? 'error' : ''}`}>
            {this.getValidationMessage('horizon-option')}
            <p className="question">I plan to begin withdrawing money from my investments in:</p>
            <input id="horizon-option-1" type="radio" name="horizon-option" value={1} onChange={this.onChange} required />
            <label htmlFor="horizon-option-1">Less than 3 years</label>
            <input id="horizon-option-2" type="radio" name="horizon-option" value={3} onChange={this.onChange} />
            <label htmlFor="horizon-option-2">3 - 5 years</label>
            <input id="horizon-option-3" type="radio" name="horizon-option" value={7} onChange={this.onChange} />
            <label htmlFor="horizon-option-3">6 - 10 years</label>
            <input id="horizon-option-4" type="radio" name="horizon-option" value={10} onChange={this.onChange} />
            <label htmlFor="horizon-option-4">11 or more years</label>
          </div>
          <button
            type="button"
            className="styled-button next-button"
            onClick={this.gotoNextPage}
            aria-label="Next"
          >
            <span>Next</span>
          </button>
        </div>
      </>,
      <>
        <h3 className="title">Your Time Horizon</h3>
        <div className="fields">
          <div className={`field radio-group ${!this.isValidField('spending-option') ? 'error' : ''}`}>
            {this.getValidationMessage('spending-option')}
            <p className="question">Once I commence withdrawing funds from my investments, I plan to spend all of the funds in:</p>
            <input id="spending-option-1" type="radio" name="spending-option" value={0} onChange={this.onChange} required />
            <label htmlFor="spending-option-1">Within 5 years</label>
            <input id="spending-option-2" type="radio" name="spending-option" value={4} onChange={this.onChange} />
            <label htmlFor="spending-option-2">6 - 10 years</label>
            <input id="spending-option-3" type="radio" name="spending-option" value={8} onChange={this.onChange} />
            <label htmlFor="spending-option-3">11 years or more</label>
          </div>
          <button
            type="button"
            className="styled-button next-button"
            onClick={this.gotoNextPage}
            aria-label="Next"
          >
            <span>Next</span>
          </button>
        </div>
      </>,
      <>
        <h3 className="title">Your Risk Tolerance</h3>
        <div className="fields">
          <div className={`field radio-group ${!this.isValidField('knowledge-option') ? 'error' : ''}`}>
            {this.getValidationMessage('knowledge-option')}
            <p className="question">I would describe my knowledge of investments as:</p>
            <input id="knowledge-option-1" type="radio" name="knowledge-option" value={0} onChange={this.onChange} required />
            <label htmlFor="knowledge-option-1">None</label>
            <input id="knowledge-option-2" type="radio" name="knowledge-option" value={2} onChange={this.onChange} />
            <label htmlFor="knowledge-option-2">Limited</label>
            <input id="knowledge-option-3" type="radio" name="knowledge-option" value={4} onChange={this.onChange} />
            <label htmlFor="knowledge-option-3">Good</label>
            <input id="knowledge-option-4" type="radio" name="knowledge-option" value={6} onChange={this.onChange} />
            <label htmlFor="knowledge-option-4">Extensive</label>
          </div>
          <button
            type="button"
            className="styled-button next-button"
            onClick={this.gotoNextPage}
            aria-label="Next"
          >
            <span>Next</span>
          </button>
        </div>
      </>,
      <>
        <h3 className="title">Your Risk Tolerance</h3>
        <div className="fields">
          <div className={`field radio-group ${!this.isValidField('concern') ? 'error' : ''}`}>
            {this.getValidationMessage('concern')}
            <p className="question">When I invest this money, I am:</p>
            <input id="concern-option-1" type="radio" name="concern" value={3} onChange={this.onChange} required />
            <label htmlFor="concern-option-1">Mostly concerned about the income my portfolio generates, I would tolerate a low level of investment market fluctuations in favour of earning a reliable income</label>
            <input id="concern-option-2" type="radio" name="concern" value={6} onChange={this.onChange} />
            <label htmlFor="concern-option-2">Concerned to ensure the investments maintain their value in terms of purchasing power</label>
            <input id="concern-option-3" type="radio" name="concern" value={8} onChange={this.onChange} />
            <label htmlFor="concern-option-3">Mostly concerned about the investment gaining value</label>
          </div>
          <button
            type="button"
            className="styled-button next-button"
            onClick={this.gotoNextPage}
            aria-label="Next"
          >
            <span>Next</span>
          </button>
        </div>
      </>,
      <>
        <h3 className="title">Your Risk Tolerance</h3>
        <div className="fields">
          <div className={`field radio-group ${!this.isValidField('scenario-option') ? 'error' : ''}`}>
            {this.getValidationMessage('scenario-option')}
            <p className="question">Consider this scenario: Imagine that in the past three months, the overall value of your share investments dropped by 25%. You understand that you require the returns from the share market to achieve your financial objectives. What would you do?</p>
            <input id="scenario-option-1" type="radio" name="scenario-option" value={0} onChange={this.onChange} required />
            <label htmlFor="scenario-option-1">Sell all of the shares, thereby reducing your chances of achieving your financial goals</label>
            <input id="scenario-option-2" type="radio" name="scenario-option" value={2} onChange={this.onChange} />
            <label htmlFor="scenario-option-2">Sell some of the shares</label>
            <input id="scenario-option-3" type="radio" name="scenario-option" value={5} onChange={this.onChange} />
            <label htmlFor="scenario-option-3">Do nothing</label>
            <input id="scenario-option-4" type="radio" name="scenario-option" value={8} onChange={this.onChange} />
            <label htmlFor="scenario-option-4">Buy more shares</label>
          </div>
          <button
            type="button"
            className="styled-button next-button"
            onClick={this.gotoNextPage}
            aria-label="Next"
          >
            <span>Next</span>
          </button>
        </div>
      </>,
      <>
        <h3 className="title">Your Risk Tolerance</h3>
        <div className="fields">
          <div className={`field radio-group ${!this.isValidField('chart-option') ? 'error' : ''}`}>
            {this.getValidationMessage('chart-option')}
            <p className="question">In the chart below we&#39;ve outlined the most likely best and worst-case annual returns of five hypothetical investment scenarios. Which range of possible outcomes is most acceptable to you?</p>
            <div className="chart" />
            <input id="chart-option-1" type="radio" name="chart-option" value={0} onChange={this.onChange} required />
            <label htmlFor="chart-option-1">Plan A</label>
            <input id="chart-option-2" type="radio" name="chart-option" value={3} onChange={this.onChange} />
            <label htmlFor="chart-option-2">Plan B</label>
            <input id="chart-option-3" type="radio" name="chart-option" value={6} onChange={this.onChange} />
            <label htmlFor="chart-option-3">Plan C</label>
            <input id="chart-option-4" type="radio" name="chart-option" value={8} onChange={this.onChange} />
            <label htmlFor="chart-option-4">Plan D</label>
            <input id="chart-option-5" type="radio" name="chart-option" value={10} onChange={this.onChange} />
            <label htmlFor="chart-option-5">Plan E</label>
          </div>
          <button
            type="submit"
            className="styled-button next-button"
            aria-label="Submit"
          >
            <span>Submit</span>
          </button>
        </div>
      </>,
    ]
  }

  getStage = (activePage) => {
    const pageNumber = activePage + 1;
    // Checks if the cases are equal to the Boolean: true
    switch (true) {
      // Stage 1 (1 Question)
      case pageNumber === 1:
        return 1;
      // Stage 2 (2 Questions)
      case pageNumber <= 3:
        this.setState({ stageProgress: `Q${pageNumber - 1}/2` });
        return 2;
      // Stage 3 (4 Questions)
      default:
        this.setState({ stageProgress: `Q${pageNumber - 3}/4` });
        return 3;
    }
  }

  gotoNextPage = () => {
    const { activePage } = this.state;
    const quizPages = this.getQuizPages();
    const numPages = quizPages.length;
    const nextPage = activePage + 1;
    if (nextPage < numPages && this.allInputsValid(activePage)) {
      window.scrollTo(0,0);
      return this.setState({
        activePage: nextPage,
        currentStage: this.getStage(nextPage)
      });
    }
    return null;
  }

  gotoPreviousPage = () => {
    const { activePage } = this.state;
    const previousPage = activePage - 1;
    this.setState({ invalidInputsObj: null });
    if (previousPage >= 0) {
      window.scrollTo(0,0);
      return this.setState({
        activePage: previousPage,
        currentStage: this.getStage(previousPage)
      });
    }
    return null;
  }

  onChange = (event) => {
    this.updateScoreObj(event);
    const { scoreObj } = this.state;
    const { quizResults } = this.props;
    const totalScore = Object.keys(scoreObj).reduce((previous, key) => {
      return previous + parseInt(scoreObj[key], 10);
    }, 0);
    let resultAsset = quizResults && quizResults[0].resultAsset.link;
    quizResults.forEach( possibleResult => {
      if (totalScore >= possibleResult.lowerBoundary && totalScore <= possibleResult.upperBoundary) {
        resultAsset = possibleResult.resultAsset.link;
      }
    });
    this.setState({
      outputPdfUrl: resultAsset
    });
  }

  render() {
    const {
      activePage,
      invalidInputsObj,
      stageProgress,
      currentStage,
      outputPdfUrl,
    } = this.state;
    const { siteUrl } = this.props;
    const quizPages = this.getQuizPages(invalidInputsObj);
    const isFirstPage = activePage === 0;
    const isLastPage = activePage === quizPages.length - 1;
    return (
      <div className="risk-assessment-quiz">
        <div className="quiz-nav">
          {!isFirstPage &&
            <button className="previous" type="button" aria-label="Previous" onClick={this.gotoPreviousPage} />
          }
          <div className="title">
            <h2>Client Risk Assessment</h2>
          </div>
          {!isLastPage &&
            <button className="next" type="button" aria-label="Next" onClick={this.gotoNextPage} />
          }
        </div>
        <div className="quiz-container">
          <div className="wrap">
            <div className="progress-container">
              <div className="stage-block">
                <span className="stage-number">{currentStage < 10 ? `0${currentStage}` : currentStage}</span>
                <span className="stage-text">Stage {stageProgress ? `- ${stageProgress}` : ''}</span>
              </div>
              <ProgressBar numStages={3} activeStage={currentStage} />
            </div>
            <form
              method="post"
              action={FORMBUCKET_ENDPOINT}
            >
              <input name="thank_you_url" value={`${siteUrl}/thank-you?query=quiz`} type="hidden" />
              <input name="file" value={outputPdfUrl} type="hidden" />
              <input type="text" name="formbucket" placeholder="Form Bucket" className="formbucket-field" />
              {quizPages.map((page, index) => (
                <div key={index} className={`page page-${index + 1} ${activePage === index ? 'active' : ''}`}>
                  {page}
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
