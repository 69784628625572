import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import Image from '../components/Image';
import { decodeEntities } from '../utils/htmlParse';
import './MapBlock.scss';

export const MapBlockTemplate = (props) => {
  const {
    map,
    headline,
    bodyText,
    options: {
      options
    }
  } = props;
  return (
    <section className="map-block">
      <div className="wrap">
        <div className="left-col">
          <Image className="map" image={map} />
          <OnVisible className="address">
            <div className="inner-square" dangerouslySetInnerHTML={{__html: options.address}} />
          </OnVisible>
        </div>
        <div className="right-col">
          <h2 className="headline">{decodeEntities(headline)}</h2>
          <div className="body">
            <div className="text" dangerouslySetInnerHTML={{__html: bodyText}} />
            <div className="contact-points">
              {/* eslint-disable-next-line */}
              <p>Call us on <a href={`tel:${options.phoneNumber}`}>{decodeEntities(options.phoneNumber)}</a></p>
              {/* eslint-disable-next-line */}
              <p>Email us at <a href={`mailto:${options.emailAddress}`}>{decodeEntities(options.emailAddress)}</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const MapBlock = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          options: wordpressAcfOptions {
            options {
              address: addressDisplay
              emailAddress
              phoneNumber
            }
          }
        }
      `}
      render={data => <MapBlockTemplate {...props} options={data.options} />}
    />
  );
}
